<template>
  <div class="activeitem">
    <!-- 赛事活动 -->
    <div class="card_item card" @click="toDetail" v-if="activeData.liveType == 0">
      <div class="cover_box">
        <img
          class="card_item_img"
          :src="QiNiuPath + activeData.matchThumbnail"
          alt
        />
      </div>
      <div class="card_infro">
        <div>
          <h2 class="card_item_title">
            <span class="card_item_title_te">
              {{ activeData.matchName }}
            </span>
          </h2>
          <p class="card_desc">{{ activeData.matchInfo }}</p>
        </div>
        <div class="card_item_time" v-show="showActiveTime">
          <img
            class="time_icon"
            src="../../assets/imgs/rili.png"
            alt
          />
          <span class="huot">活动时间 </span> {{ activeData.matchStartTime }}-{{ activeData.matchEndTime }}
        </div>
      </div>
      <div v-show="showActiveTime">
        <img
              class="card_label"
              :src="
                  activeData.status == 1
                  ? require('../../assets/imgs/weis.png')
                  : activeData.status == 2
                  ? require('../../assets/imgs/jin.png')
                  : require('../../assets/imgs/ends.png')
              "
              alt

            />
      </div>
    </div>


    <!-- 移动端直播 -->
    <div v-else-if="activeData.liveType == 1 || activeData.liveType == 2" class="card_item card_test1">
      <div class="cover_box">
        <img
          class="card_item_img"
          :src="QiNiuPath + activeData.matchLiveConfig.liveLogo"
          alt
        />
        <img class="limg" v-if="activeData.liveType == 1" src="../../assets/imgs/tuz.png" alt="">
        <img class="limg" v-else-if="activeData.liveType == 2" src="../../assets/imgs/tianz.png" alt="">
      </div>
      <div class="card_infro">
        <div>
          <h2 class="card_item_title">
            <span class="card_item_title_te">
              {{ activeData.matchLiveConfig.liveName }}
            </span>

            <span class="zhiboz" v-if="activeData.matchLiveConfig.liveStatus == 1">
              直播中
            </span>
            <!-- <span v-else-if="activeData.matchLiveConfig.liveStatus == 0" class="weiboz">
              <img src="../../assets/imgs/wei.png" alt="">
            </span> -->

            <div class="zhibox" v-if="activeData.matchLiveConfig.liveStatus == 0 || activeData.matchLiveConfig.liveStatus == 1">
              <img class="erx" src="../../assets/imgs/erw.png" alt="">
              <span>扫码看直播</span>
              <img class="erb" :src="QiNiuPath + activeData.matchLiveConfig.liveQrcode" alt="">
            </div>
            <div class="zhibox" v-else-if="activeData.matchLiveConfig.liveStatus == 2 && activeData.matchLiveConfig.liveType == 1">
              <img class="erx" src="../../assets/imgs/erw.png" alt="">
              <span>扫码看回顾</span>
              <img class="erb" :src="QiNiuPath + activeData.matchLiveConfig.liveQrcode" alt="">
            </div>

          </h2>
          <p class="card_desc">{{ activeData.matchLiveConfig.liveInfo }}</p>
        </div>
        <div class="card_item_time">
          <div style="display: inherit;" v-if="activeData.matchLiveConfig.liveStatus == 1 || activeData.matchLiveConfig.liveStatus == 2">
            <img
              class="time_icon time_iconh"
              src="../../assets/imgs/rili.png"
              alt
            />
            <div>
              <span class="huot">直播时间 </span>
              {{ starttim }} - {{ endtim }}
            </div>
          </div>
          <div v-else>
            <img class="time_icon time_icont" style="display: inline-block;" src="../../assets/imgs/dao.png" alt="">
            <div class="dao">倒计时</div>
            <div class="dtime">{{startday}}天 {{starthr}} : {{startmin}} : {{startsec}}</div>
          </div>
        </div>
      </div>
      <div>
        <img
              class="card_label"
              :src="
                  activeData.matchLiveConfig.liveStatus == 0
                  ? require('../../assets/imgs/weis.png')
                  : activeData.matchLiveConfig.liveStatus == 1
                  ? require('../../assets/imgs/jin.png')
                  : require('../../assets/imgs/ends.png')
              "
              alt
            />
      </div>
    </div>


    <!-- 第三方PC端直播 -->
    <div v-else class="card_item card_test2 card" @click="toDetailt">
      <div class="cover_box">
        <img
          class="card_item_img"
          :src="QiNiuPath + activeData.matchLiveConfig.liveLogo"
          alt
        />
        <img class="limg" src="../../assets/imgs/sanz.png" alt="">
      </div>
      <div class="card_infro">
        <div>
          <h2 class="card_item_title">
            <span class="card_item_title_te">
              {{ activeData.matchLiveConfig.liveName }}
            </span>
            <span class="zhiboz" v-if="activeData.matchLiveConfig.liveStatus == 1">
              直播中
            </span>
            <!-- <span v-else-if="activeData.matchLiveConfig.liveStatus == 0" class="weiboz">
              <img src="../../assets/imgs/wei.png" alt="">
            </span> -->
          </h2>
          <p class="card_desc">{{ activeData.matchLiveConfig.liveInfo }}</p>
        </div>
        <div class="card_item_time">
          <div style="display: inherit;" v-if="activeData.matchLiveConfig.liveStatus == 1 || activeData.matchLiveConfig.liveStatus == 2">
            <img
              class="time_icon time_iconh"
              src="../../assets/imgs/rili.png"
              alt
            />
            <div>
              <span class="huot">直播时间 </span> {{ starttim }} - {{ endtim }}
            </div>
          </div>
          <div v-else>
            <img class="time_icon time_icont" style="display: inline-block;" src="../../assets/imgs/dao.png" alt="">
            <div class="dao">倒计时</div>
            <div class="dtime">{{startday}}天 {{starthr}} : {{startmin}} : {{startsec}}</div>
          </div>
        </div>
      </div>
      <div>
        <img
              class="card_label"
              :src="
                  activeData.matchLiveConfig.liveStatus == 0
                  ? require('../../assets/imgs/weis.png')
                  : activeData.matchLiveConfig.liveStatus == 1
                  ? require('../../assets/imgs/jin.png')
                  : require('../../assets/imgs/ends.png')
              "
              alt
            />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from 'moment';
import Axios from 'axios';
export default {
  data() {
    return {
      startday: 0,
      starthr: 0,
      startmin: 0,
      startsec: 0,
      endday: 0,
      endthr: 0,
      endmin: 0,
      endsec: 0,
      now:'',
      timer:null,
      starttim:'1970-1-1 00:00:00',
      endtim:'1970-1-1 00:00:00'
    };
  },
  props: {
    activeData: {
      type:Object,
      default:()=>{ return {}}
    },
  },
  computed: {
    ...mapState({
      QiNiuPath: (state) => state.indexStore.QiNiuPath,
    }),
    showActiveTime() {
      const st = this.activeData.matchStartTime
      const et = this.activeData.matchEndTime
      return !(st.includes('9999') || et.includes('9999'));
    }
  },
  watch:{
    activeData:{
     handler(newName, oldName) {
         if(this.activeData.matchLiveConfig != null){
          this.startday='00';
          this.starthr='00';
          this.startmin='00';
          this.startsec='00';
           clearInterval(this.timer);
          this.countDown()
        }
        Date.prototype.format = function(fmt) {
          var o = {
              "M+" : this.getMonth()+1,                 //月份
              "d+" : this.getDate(),                    //日
              "h+" : this.getHours(),                   //小时
              "m+" : this.getMinutes(),                 //分
              "s+" : this.getSeconds(),                 //秒
              "q+" : Math.floor((this.getMonth()+3)/3), //季度
              "S"  : this.getMilliseconds()             //毫秒
          };
          if(/(y+)/.test(fmt)) {
                  fmt=fmt.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length));
          }
          for(var k in o) {
              if(new RegExp("("+ k +")").test(fmt)){
                  fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
              }
          }
          return fmt;
        }
   if(this.activeData.matchLiveConfig !=null){
      this.starttim = new Date(this.activeData.matchLiveConfig.startTime).format("yyyy年MM月dd日 hh:mm:ss");
      this.endtim = new Date(this.activeData.matchLiveConfig.endTime).format("yyyy年MM月dd日 hh:mm:ss");
     }
      },
      immediate: true,
      // deep:true
    }
  },
  methods: {
    // infoWndClick() {
    //     console.log('这是子组件的方法');
    //     alert('11111111')
    // },
    toDetail() {
      this.$emit("toDetail", this.activeData.jumpUrl);
    },
    toDetailt() {
      console.log(33333333333)
      this.$emit("toDetailt", this.activeData.matchLiveConfig.pcAddress);
    },

    countDown(){
      var that = this
          let now = Date.parse(new Date(this.activeData.matchLiveConfig.currentTime));
          const start = Date.parse(new Date(this.activeData.matchLiveConfig.startTime));
          const end = Date.parse(new Date(this.activeData.matchLiveConfig.endTime));
          // const start = Date.parse(new Date('2020-12-27 12:00:00'));
          // const end = Date.parse(new Date('2020-12-27 12:00:20'));
          this.timer = setInterval(()=>{
            now = now + 1000;
            if(now>start && now <= end){
              this.activeData.matchLiveConfig.liveStatus = 1

            }else if(now>end){
              this.activeData.matchLiveConfig.liveStatus = 2
              this.activeData.status =3
              clearInterval(this.timer);
            }
            this.setTime(start,now)
          },1000);
        },

      setTime(start,end){
          let msec = start - end
          let startday = parseInt(msec / 1000 / 60 / 60 / 24)
          let starthr = parseInt(msec / 1000 / 60 / 60 % 24)
          let startmin = parseInt(msec / 1000 / 60 % 60)
          let startsec = parseInt(msec / 1000 % 60)
          this.startday = startday
          this.starthr = starthr > 9 || starthr < 0 ? starthr : '0' + starthr
          this.startmin = startmin > 9 || startmin < 0 ? startmin : '0' + startmin
          this.startsec = startsec > 9 || startsec < 0 ? startsec : '0' + startsec
          // console.log(this.startday,this.starthr,this.startmin ,this.startsec)
      }
  },
  mounted: function () {
    // // if(this.activeData.matchLiveConfig){
    // //   console.log('执行this.countDown()')
    //   this.countDown()
    // //   console.log(this.countDown())
    // // }
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/mixin.scss";
.time_icont {
  margin-bottom: 4px;
}
.card {
  cursor: pointer;
}
.activeitem {
  border-radius: 4px;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  // cursor: pointer;
  margin-bottom: 30px;
  transition: all 0.4s ease;
  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.2);
  }
  .card_item {
    width: 100%;
    padding: 26px 26px 26px 26px;
    display: flex;
    // transition: all 0.4s ease;
    .card_label {
      @include wh(40px, 100px);
      margin-right: 0px;
      margin-top: -26px;
    }
    .cover_box {
      @include wh(304px, 209px);
      margin-right: 40px;
      position: relative;
    }
    .card_item_img {
      @include wh(304px, 209px);
      // border-radius: 4px;
      overflow: hidden;
      transition: all 0.3s ease-out;
    }
    .card_infro {
      box-sizing: border-box;
      height: 198px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.4s ease-out;
      height: inherit;
      // flex: 1;
      width: 760px;
      // overflow: hidden;
      .card_item_title {
        @include sc(24px, #1c222e);
        font-weight: bold;
        @include fa();
        margin-bottom: 12px;
        .card_item_title_te {
          max-width: 520px;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .card_desc {
        @include sc(16px, #9aa7b9);
        @include mtxtflow(3);
        overflow: hidden;
        max-height: 72px;
      }
      .card_item_time {
        @include sc(16px, #9aa7b9);
        @include fa();
        .time_icon {
          @include wh(20px, 20px);
          margin-right: 6px;
        }
      }
    }
  }
}
.zhibox {
  width: 124px;
  height: 34px;
  border-radius: 17px;
  border: 1px solid #5D5AED;
  line-height:28px;
  cursor: pointer;
  position: relative;
  margin-left: 8px;
}
.erb {
  width: 130px;
  height: 130px;
  position: absolute;
  left: 123px;
  top: -14px;
}
.erb {
  display: none;
}
.zhibox:hover .erb {
  display: block;
}
.zhibox .erx {
  width:18px;
  height:18px;
  margin-left: 10px;
}
.zhibox span {
  font-size:16px;
  color: #5D5AED;
  margin-left: 5px;
}
.zhiboz {
  background:url(../../assets/imgs/kaiz.gif);
  display: inline-block;
  width: 84px;
  height: 30px;
  background-size:84px 32px;
  font-size: 14px;
  color:#fff;
  padding-left:38px;
  line-height:30px;
  margin-left: 10px;
}
.weiboz {
   margin-left: 10px;
}
.weiboz img{
  width: 84px;
  height: 30px;
}
.time_iconh {
  margin-top: 2px;
}
.huot {
  display: inline-block;
  font-size: 18px;
  color:#333A48;
}
.dao {
  display: inline-block;
  font-size:18px;
  color:#333A48;
  margin-right:4px;
}
.dtime {
  display: inline-block;
  font-size:16px;
  color:#9AA7B9
}
.limg {
  position: absolute;
  left: 0;
  bottom: 0;
  width:54px;
  height: 40px;
}
</style>
